<template>
    <el-row>
        <el-row style="display: flex; align-items: center;">
            <el-col :span="22" class="component-title" v-if="!isMobile()">
                Generate Coupons
            </el-col>
            <el-col v-else>
                &nbsp;
            </el-col>
            <el-col :span="2" v-if="!isMobile()">
                <profile-avatar></profile-avatar>
            </el-col>
        </el-row>
        <el-row style="height: 90vh; overflow-y: auto; width: 100%; padding: 5vh 2vw;">
            <el-form :inline="true" :model="formInline" class="demo-form-inline">
                <el-form-item label="Coupon Code">
                    <el-input placeholder="Coupon Code" v-model="formInline.coupon_code"></el-input>
                </el-form-item>
                <el-form-item label="Max Users allowed">
                    <el-input placeholder="Max Users allowed" v-model="formInline.max_users"></el-input>
                </el-form-item>
                <el-form-item label="Discount Percentage">
                    <el-input placeholder="Discount Percentage" v-model="formInline.discount_percentage"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button @click="generateCoupons" type="primary">Generate</el-button>
                </el-form-item>
            </el-form>
            <el-row style="color: red;">
                {{error}}
            </el-row>
            <h2>Available Coupons</h2>
            <el-row v-if="getTableData.length > 0">
                <data-tables :filters="filters" :data="getTableData" :page-size="5"
                             :pagination-props="{ pageSizes: [5, 10] }">
                    <el-row slot="tool" style="margin: 10px 0">
                        <el-col :xl="5" :lg="5" :md="8" :sm="24" :xs="24">
                            <el-input placeholder="Code/Discount" v-model="filters[0].value"></el-input>
                        </el-col>
                    </el-row>
                    <el-table-column fixed label="Coupon Code" prop="coupon_code" width="auto"></el-table-column>
                    <el-table-column label="Discount Percentage" prop="discount_percentage"
                                     width="auto"></el-table-column>
                    <el-table-column label="Max Users Allowed" prop="max_users" width="auto"></el-table-column>
                    <el-table-column label="Total Used" prop="total_used" width="auto"></el-table-column>
                    <el-table-column label="Created At" prop="created" width="auto"></el-table-column>
                </data-tables>
            </el-row>
        </el-row>
    </el-row>

</template>

<script>
  import ProfileAvatar from '../../main_components/ProfileAvatar';

  export default {
    name: 'GenerateCoupons',
    components: {ProfileAvatar},
    computed: {
      getTableData: function() {
        return this.couponCodes;
      },
    },
    data: function() {
      return {
        loadingScreen: false,
        formInline: {
          coupon_code: '',
          max_users: null,
          discount_percentage: null,
        },
        filters: [
          {
            value: '',
            prop: ['coupon_code', 'discount_percentage'],
          }],
        couponCodes: [],
        error: '',
      };
    },
    beforeMount() {
      let self = this;
      self.checkUserLoggedIn().then(response => {
        self.$store.state.loadingScreen = false;
      }).catch(error => {
        error;
        self.$store.state.loadingScreen = false;
      });
    },
    mounted() {
      this.listCoupons();
    },
    methods: {
      listCoupons: function() {
        let self = this;
        self.$store.state.loadingScreen = true;
        let url = self.getBaseUrl() + '/accounts/coupon/list/';
        $.ajax({
          url: url,
          beforeSend: function(request) {
            if (self.getAuthToken() !== '')
              request.setRequestHeader('Authorization',
                  'Token ' + self.getAuthToken());
          },
          type: 'GET',
          success: function(response) {
            self.couponCodes = response;
            self.$store.state.loadingScreen = false;
          },
          error: function(error) {
            self.$store.state.loadingScreen = false;
            self.error = error;
          },
        });
      },
      generateCoupons: function() {
        let self = this;
        let url = self.getBaseUrl() + '/accounts/coupon/create/';
        self.$store.state.loadingScreen = true;
        $.ajax({
          url: url,
          beforeSend: function(request) {
            if (self.getAuthToken() !== '')
              request.setRequestHeader('Authorization',
                  'Token ' + self.getAuthToken());
          },
          contentType: 'application/json',
          type: 'POST',
          data: JSON.stringify(self.formInline),
          success: function(response) {
            response;
            self.$store.state.loadingScreen = false;
            self.listCoupons();
          },
          error: function(error) {
            self.$store.state.loadingScreen = false;
            self.error = error.responseJSON[0];
          },
        });
      },
    },
  };
</script>

<style scoped>

</style>
